/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ILayout, useLayout } from "../../core";
import { siteConfig } from "../../../../config/siteConfig";

const Footer = () => {
  const { config } = useLayout();
  useEffect(() => {
    updateDOM(config);
  }, [config]);

  const applicationMode = localStorage.getItem("application_mode");
  return (
    <>
      <div className="text-dark order-2 order-md-1">
        <span className="fw-bolder me-1" style={{ color: applicationMode === "light" ? "#03045e" : "#ffffff" }}>
          {new Date().getFullYear().toString()} &copy;
        </span>
        <a
          href={siteConfig?.companynamepoweredbyurl}
          target="_blank"
          style={{ color: applicationMode === "light" ? "#03045e" : "#ffffff", fontWeight: "bolder", letterSpacing: "1px"}}
        >
          {siteConfig?.companynamepoweredby}
        </a>
      </div>

      {/* <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
        <li className="menu-item">
          <a
            href={siteConfig?.abouturl}
            target="_blank"
            className="menu-link px-2"
            style={{ color: applicationMode === "light" ? "#03045e" : "#ffffff" }}
          >
            About
          </a>
        </li>

        <li className="menu-item">
          <a
            href={siteConfig?.supporturl}
            target="_blank"
            className="menu-link px-2"
            style={{ color: applicationMode === "light" ? "#03045e" : "#ffffff" }}
          >
            Support
          </a>
        </li>
        <li className="menu-item">
          <Link to="/terms" className="menu-link px-2" style={{ color: applicationMode === "light" ? "#03045e" : "#ffffff" }}>
            Privacy
          </Link>
        </li>
      </ul> */}
    </>
  );
};

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add("data-kt-app-footer-fixed", "true");
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add("data-kt-app-footer-fixed-mobile", "true");
  }
};

export { Footer };
