import axios from 'axios';

const retryRequest = async (url: string, payload: any, retries: number = 8, delay: number = 1000): Promise<any> => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      // Perform the API call
      await axios.post(url, payload);
      return true;
    } catch (error) {
      if (attempt === retries) {
        console.error('Max retries reached. Error:', error);
        throw error;
      }
      console.log(`Retrying (${attempt}/${retries})...`);
      await new Promise((resolve) => setTimeout(resolve, delay)); // Wait before retrying
    }
  }
};

export { retryRequest };