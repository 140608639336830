import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);

interface FormatDateOptions {
  onlyTime?: boolean;
}

export function formatDateTime(
  dateString: string,
  options: FormatDateOptions = {}
) {
  const date = dayjs(dateString);

  if (options.onlyTime) {
    return date.format("hh:mm A");
  }

  return date.format("DD MMMM YYYY [at] hh:mm A");
}


export function returnUTCDate(){
  return dayjs().utc().format("YYYY-MM-DD HH:mm:ss");
}