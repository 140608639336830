import { FC } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Box, Button } from "@mui/material";
import { StyledTypography } from "../../../../utils/StyledTypographyHeadings";

const Error404: FC = () => {
  return (
    <>
      {/* begin::Title */}
      <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Oops!</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className="fw-semibold fs-6 text-gray-500 mb-7">
        Page Not Found
      </div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className="mb-3">
        <img
          src={toAbsoluteUrl("/media/auth/404-error.png")}
          className="mw-100 mh-300px theme-light-show"
          alt=""
        />
        <img
          src={toAbsoluteUrl("/media/auth/404-error-dark.png")}
          className="mw-100 mh-300px theme-dark-show"
          alt=""
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <Link to="/home/dashboard" className="btn2">
          <Button
            className="btn2"
            sx={{
              padding: "2px !important",
              textTransform: "capitalize !important",
            }}
          >
            <StyledTypography variant="h6" sx={{color: "#fff !important"}}>Return Home</StyledTypography>
          </Button>
        </Link>
      </Box>
      {/* end::Link */}
    </>
  );
};

export { Error404 };
